import React from 'react';
import { useTranslation } from "react-i18next";

import ShieldPermission from './ShieldPermission';
import Api from '../../api/Api';

const ShieldDownload = (props) => {
  const { t } = useTranslation();
  const styles = {
    wrap: {
      fontSize: '13px',
    },
    p: {
      backgroundColor: '#fff',
      padding: '4px 8px',
    },
    contentWrap: {
      border: '1px solid rgb(238, 238, 238)',
      backgroundColor: '#fff',
    },
  };

  const { setShield } = props;
  return (
    <div style={styles.wrap}>
      <div className="mb-2">
        <p style={styles.p}>
          {t('The file downloading now contains Security document.')}
        </p>
      </div>
      <div className="p-3" style={styles.contentWrap}>
        <p>
          ・{t('Encrypted documents are saved as .irm extension')}
        </p>
        <p>
          {t('・Encrypted file must be decrypted with dedicated program after downloading.')}
        </p>
        <p style={{ marginBottom: '30px' }}>
          {t('・Enter the recipient email and link password in the authentication page provided when running file after installation.')}
        </p>
        <div
          className="mb-2 p-4 clearfix"
          style={{
            backgroundColor: '#EAEAEA',
          }}
        >
          <p>
            {t('Please install dedicated program to open irm document.')}
          </p>
          <div style={{ float: 'right' }}>
            <img
              src="/static/bundles/img/ic_ab_down.png"
              alt=""
              style={{
                height: '18px',
                width: '18px',
                marginRight: '4px',
              }}
            />
            <span
              style={{
                color: '#337ab7',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => {
                // 다운로드
                const url = '/openapi/download/shield';
                Api.getData(url, (result) => {
                  if (result.success) {
                    console.log('download', result.link);
                    window.location.href = result.link;
                  }
                })
              }}
            >
              {t('Download DirectCloud-SHIELD')}
            </span>
          </div>
        </div>
      </div>
      {
        false && (
          <div className="clearfix mt-4">
            <button
              type="button"
              className="btn btn-success col-xs-3"
              style={{ float: 'right' }}
              onClick={() => {
                setShield(false);
              }}
            >
              {t('OK')}
            </button>
          </div>
        )
      }
    </div>
  );
};

export default ShieldDownload;
