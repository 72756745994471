import * as React from 'react';
import ReactDOM from 'react-dom';

import ToastContent from './ToastContent';

export default class Toast {
  component;
  content;

  onShow = (text, hText) => {
    console.log('onShow');
    this.component = document.createElement('div');
    if (document.body) {
      document.body.appendChild(this.component);
      if (this.component) {
        ReactDOM.render(
          <ToastContent
            ref={(ref) => {
              this.content = ref;
            }}
            text={text}
            hText={hText}
          />,
          this.component,
        );
      }
    }
  };

  onHide = () => {
    console.log('onHide');
    ReactDOM.unmountComponentAtNode(this.component);
    if (document.body && this.component) {
      document.body.removeChild(this.component);
      this.component = undefined;
    }
  };
}
