export default function getFileIcon(ext) {
    const iconMap = {
      // Folder
      folder: '/static/bundles/img/ic_folder.png',
  
      // Files
      doc: '/static/bundles/img/ic_doc.png',
      docx: '/static/bundles/img/ic_doc.png',
      post: '/static/bundles/img/ic_doc.png',
  
      xls: '/static/bundles/img/ic_xls.png',
      xlsx: '/static/bundles/img/ic_xls.png',
  
      ppt: '/static/bundles/img/ic_ppt.png',
      pptx: '/static/bundles/img/ic_ppt.png',
  
      pdf: '/static/bundles/img/ic_pdf.png',
  
      hwp: '/static/bundles/img/ic_hwp.png',
      hwpx: '/static/bundles/img/ic_hwp.png',
  
      epub: '/static/bundles/img/ic_epub.png',
  
      txt: '/static/bundles/img/ic_txt.png',
  
      ai: '/static/bundles/img/ic_ai.png',
  
      psd: '/static/bundles/img/ic_psd.png',
  
      dwg: '/static/bundles/img/ic_dwg.png',
      dxf: '/static/bundles/img/ic_dwg.png',
  
      zip: '/static/bundles/img/ic_zip.png',
      rar: '/static/bundles/img/ic_zip.png',
      tar: '/static/bundles/img/ic_zip.png',
      lzh: '/static/bundles/img/ic_zip.png',
      '7z': '/static/bundles/img/ic_zip.png',
      alz: '/static/bundles/img/ic_zip.png',
      egg: '/static/bundles/img/ic_zip.png',
  
      avi: '/static/bundles/img/ic_avi.png',
      wmv: '/static/bundles/img/ic_avi.png',
      mp4: '/static/bundles/img/ic_avi.png',
      mov: '/static/bundles/img/ic_avi.png',
      mpg: '/static/bundles/img/ic_avi.png',
      mpeg: '/static/bundles/img/ic_avi.png',
      asf: '/static/bundles/img/ic_avi.png',
      mkv: '/static/bundles/img/ic_avi.png',
      '3gp': '/static/bundles/img/ic_avi.png',
      ogv: '/static/bundles/img/ic_avi.png',
      webm: '/static/bundles/img/ic_avi.png',
  
      mp3: '/static/bundles/img/ic_mp3.png',
      ogg: '/static/bundles/img/ic_mp3.png',
      aac: '/static/bundles/img/ic_mp3.png',
      wma: '/static/bundles/img/ic_mp3.png',
      m4a: '/static/bundles/img/ic_mp3.png',
      wav: '/static/bundles/img/ic_mp3.png',
      flac: '/static/bundles/img/ic_mp3.png',
  
      jpg: '/static/bundles/img/ic_jpg.png',
      jpeg: '/static/bundles/img/ic_jpg.png',
      png: '/static/bundles/img/ic_jpg.png',
      gif: '/static/bundles/img/ic_jpg.png',
      bmp: '/static/bundles/img/ic_jpg.png',
      tiff: '/static/bundles/img/ic_jpg.png',
      tif: '/static/bundles/img/ic_jpg.png',
      dcm: '/static/bundles/img/ic_jpg.png',
      orf: '/static/bundles/img/ic_jpg.png',
      cr2: '/static/bundles/img/ic_jpg.png',
      arw: '/static/bundles/img/ic_jpg.png',
      nef: '/static/bundles/img/ic_jpg.png',
      pct: '/static/bundles/img/ic_jpg.png',
      pict: '/static/bundles/img/ic_jpg.png',
      eps: '/static/bundles/img/ic_jpg.png',
      svg: '/static/bundles/img/ic_jpg.png',
    };
  
    return iconMap[ext] || '/static/bundles/img/ic_etc.png';
  }
  