import React, { Component } from 'react';
import videojs from 'video.js';

import LoaderBox from '../motions/LoaderBox';

class VideoPlayer extends Component {
  constructor(props) {
    super(props);
    this.playerNode = React.createRef();
    this.state = {
      isReady: false,
    };
  }

  componentDidMount() {
    const { isVideo } = this.props;
    if (isVideo) {
      this.init();
    } else {
      setTimeout(() => {
        this.setState({
          isReady: true,
        });
      }, 2000);
    }
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
    this.setState({
      isReady: false,
    });
  }

  init = () => {
    const {
      handleSetVideoError,
      handleSetIsNotSupportFormat,
    } = this.props;

    if (this.player) {
      this.player.dispose();
    }

    setTimeout(() => {
      this.handleSetIsReady();
      this.player = videojs(this.playerNode.current, this.props, () => {
        
        this.player.on('canplay', () => {
          this.player.removeClass('hidden');
        });

        this.player.on('error', () => {
          const error = this.player.error();
          console.log('error', error.code);
          this.player.dispose();

          if (error.code === 4) {
            handleSetIsNotSupportFormat();
          } else {
            handleSetVideoError();
          }
        });
      });
    }, 2000);
  }

  handleSetIsReady = () => {
    this.setState({
      isReady: true,
    });
  }

  renderVideoPlayer = () => {
    const styles = {
      videoWrap: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      },
    };

    return (
      <div style={styles.videoWrap} data-vjs-player>
        <video
          ref={this.playerNode}
          playsinline
          autoPlay
          muted
          className="video-js inline-block hidden vjs-big-play-centered"
        />
      </div>
    );
  }

  renderAudioPlayer = () => {
    const { sources } = this.props;
    const styles = {
      videoWrap: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      },
    };

    return (
      <div style={styles.videoWrap} data-vjs-player>
        <audio
          id="audio_example"
          className="vjs-default-skin"
          controls
          autoPlay
          controlsList="nodownload"
        >
          <source
            src={sources && sources.src}
            type="audio/mp3"
          />
        </audio>
      </div>
    );
  }

  render() {
    const { isReady } = this.state;
    const { isVideo } = this.props;
    const render = isVideo ? this.renderVideoPlayer() : this.renderAudioPlayer();
    return (
      <div style={{ height: 'calc(100% - 44px)' }}>
        {
          isReady ? (
            render
          ) : (
            <div className="loader-appview">
              <LoaderBox />
            </div>
          )
        }
      </div>
    );
  }
}

export default VideoPlayer;
