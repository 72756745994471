import { observable, decorate, action } from 'mobx';

class LoaderStore {
  constructor() {
    this.loaded = true;
  }
  
  setLoaded = (loaded) => {
    this.loaded = loaded;
  }
}

decorate(LoaderStore, {
  loaded: observable,
  setLoaded: action,
});

const loaderStore = new LoaderStore();

export default loaderStore;
export { LoaderStore };
