import React, { useState } from 'react';

import { useTranslation } from "react-i18next";

import Api from '../../api/Api';

const Header = (props) => {
  const { t } = useTranslation();
  const [ showLang, setShowLang ] = useState(false);

  const renderLanguges = () => {
    return (
      <ul className='Language'>
        <li className='language__li' onClick={() => {props.setLanguage('en')}}>
          <img
            alt=""
            src="/static/bundles/img/eng.png"
            style={{
              verticalAlign: 'sub',
              margin: '3px',
            }}
          />  
          English
        </li>
        <li className='language__li' onClick={() => {props.setLanguage('ko')}}>
          <img alt="" src="/static/bundles/img/kor.png" style={{ verticalAlign: 'sub', margin: '3px' }} />    
          한국어
        </li>
        <li className='language__li' onClick={() => {props.setLanguage('ja')}}>
          <img alt="" src="/static/bundles/img/jpn.png" style={{ verticalAlign: 'sub', margin: '3px' }} />  
          日本語
        </li>
      </ul>
    );
  }

  let currentLang = '';
  if (t('lang') === 'eng') {
    currentLang = 'English';
  } else if (t('lang') === 'kor') {
    currentLang = '한국어';
  } else if (t('lang') === 'jpn') {
    currentLang = '日本語';
  }

  return (
    <div className='Header'>
      <a
        className='header__logo'
        // href={props.link.home_url} 홈 이동 삭제 요청으로 인한 숨김
        target="_blank"
        rel="noopener"
        // onClick={() => {
        //   Api.sendGaEventLog('Link-Logo')
        // }}
      >
        <img
          src={props.link.logo || props.logo}
          alt=''
          style={{
            width: '130px',
            height: '30px',
          }}
       />
      </a>
      {
        <div className='header__lang'>
          <ul className='header__lang-ul'>
            <li className='header__lang-li'>
              <div
                className='header__lang-btn'
                onClick={() => {
                  setShowLang(!showLang);
                }}
              >
                <img
                  alt=""
                  src={`/static/bundles/img/${t('lang')}.png`}
                />
                {currentLang}
                <i className="fa fa-angle-down"></i>
                {showLang && renderLanguges()}
              </div>
            </li>
          </ul>
        </div>
      }
      {
        <div className='header__sign-wrap'>
          <a
            href={props.link.login_url}
            target="_blank"
            className="header__sign-btn"
            rel="noopener"
            onClick={() => {
              Api.sendGaEventLog('Link-Login')
            }}
          >
            <i className="fa fa-sign-in"/>
            {t('SIGN IN')}
          </a>
          {
            // 200914 회원가입 숨김 요청
            false && (
              <a
                href={props.link.join_url}
                target="_blank"
                rel="noopener"
                className="header__sign-btn"
                onClick={() => {
                  Api.sendGaEventLog('Link-Trial')
                }}
              >
                <i className="fa fa-user" />
                  {t('SIGN UP')}
              </a>
            )
          }
        </div>
      }
    </div>
  )
}

export default Header;
