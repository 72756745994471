import React, { Fragment, useState, useEffect } from 'react';
import toastr from 'toastr';
import { observer } from 'mobx-react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Cookies from 'js-cookie';
import i18next from 'i18next';
import Loader from 'react-loader';
import ReactGA from 'react-ga';

import { useTranslation } from "react-i18next";

import Header from './components/header/Header';
import Content from './components/content/Content';

import ExpiredModal from './components/modal/ExpiredModal';
import PasswordModal from './components/modal/PasswordModal';
import AuthModal from './components/modal/AuthModal';
import UploadModal from './components/modal/UploadModal';
import DocumentViewerModal from './components/modal/DocumentViewerModal'

import Api from './api/Api';

import loaderStore from './mobx/LoaderStore';
import uploadStore from './mobx/UploadStore';
import Toast from './components/alert/Toast';
import ShieldModal from './components/modal/ShieldModal';
import shieldStore from './mobx/ShieldStore';
import Shield from './components/bottom/Shield';
import videoStore from './mobx/VideoStore';

import './App.scss';
import TopAlert from './components/TopAlert';

const toast = new Toast();

// Google Analytics
const gaTrackingID = 'UA-51788184-1';
ReactGA.initialize(gaTrackingID, {
  debug: false,
});

const Main = observer(({match}) => {
  const { t } = useTranslation();

  const [ logo, setLogo ] = useState('/static/bundles/img/logo_directcloud_white.svg');
  const [ token, setToken ] = useState('');
  const [ expired, setExpired ] = useState(false);
  const [ usePwd, setUsePwd ] = useState(false);
  const [ auth, setAuth ] = useState(false);
  const [ authEmail, setAuthEmail ] = useState(Cookies.get(`${match.params.linkId}_email`) || '');
  const [ upload, setUpload ] = useState(false);
  const [ viewer, setViewer ] = useState(false);
  const [ previewData, setPreviewData ] = useState('');
  const [ useDownload, setUseDownload ] = useState(false);
  const [ shield, setShield ] = useState(false);
  const [ shieldData, setShieldData ] = useState({});

  const [ link, setLink ] = useState({});
  const [ list, setList ] = useState({});
  const [ message, setMessgage ] = useState('');

  const { files, setFiles } = uploadStore;

  const [topHeight, setTopHeight] = useState(55);
  const [showTopAlert, setShowTopAlert] = useState(false);

  useEffect(() => {
    // linkId 없을때 directcloud.jp로 이동
    const host = window.location.host;
    const isDCB = !host.includes('ismcloudone');
    const search = window.location.search;

    const queryLang = search.indexOf('?lang') === 0 && search.substring(6, 9);
    if (queryLang) {
      let language = navigator.language.substring(0, 2);
      if (queryLang === 'kor') {
        language = 'ko';
      } else if (queryLang === 'eng') {
        language = 'en';
      } else if (queryLang === 'jpn') {
        language = 'ja';
      }
      setLanguage(language);

      if (search && search.substring(10).indexOf('token') === 0) {
        Cookies.set(`${match.params.linkId}_post`, search.substring(16), { expires: 1 });
      }

      window.location.href = window.location.origin + window.location.pathname;
    }

    if (!isDCB) {
      console.log('ISMCLOUD');
      setLogo('/static/bundles/img/logo_vendor_ism.png');
      Cookies.set('HOST', 'ismcloudone', { expires: 1 })
    } else {
      console.log('DCBCLOUD');
      Cookies.set('HOST', 'directcloud', { expires: 1 })
    }

    if (match.params.linkId === undefined) {
      if (isDCB) {
        window.location.href = 'https://directcloud.jp';
      } else {
        window.location.href = 'https://ismcloudone.com';
      }
    }

    if (match.params.linkId === 'sockjs-node') {
      return;
    }

    getLink();
  }, []);

  const setLanguage = (code) => {
    i18next.changeLanguage(code);
    
    if (code === 'ko') {
      Cookies.set('lang', 'kor', { expires: 30});
    } else if (code === 'en') {
      Cookies.set('lang', 'eng', { expires: 30});
    } else if (code === 'ja') {
      Cookies.set('lang', 'jpn', { expires: 30});
    }

    const html = document.querySelector('html');
    html.setAttribute('lang', code);
  }

  const setUploadModal = (isShow) => {
    if (!isShow) {
      setFiles([]);
    }

    setUpload(isShow);
  }

  const getLink = () => {
    shieldStore.setLinkId(match.params.linkId);
    const isWorkflow = Cookies.get(`${match.params.linkId}_post`) !== undefined;
    const params = {
      id: match.params.linkId,
    }

    if (isWorkflow) {
      params.access_token = Cookies.get(`${match.params.linkId}_post`)
    }

    const url = '/openapi/link/info';
    Api.postData(
      url,
      params,
      (res) => {
        console.log('getLink()', res, params, isWorkflow);

        let topAlert = false;
        if (Api.detectIE()) {
          topAlert = true;
          const checkSessionStorage = sessionStorage.getItem('showTopAlert');
          if (checkSessionStorage === 'false') {
            topAlert = false;
          }
        }
      
        const height = topAlert ? 80 : 55;
        setTopHeight(height);
        setShowTopAlert(topAlert);

        let language = navigator.language.substring(0, 2)
        if (Cookies.get('lang') === 'kor') {
          language = 'ko';
        } else if (Cookies.get('lang') === 'eng') {
          language = 'en';
        } else if (Cookies.get('lang') === 'jpn') {
          language = 'ja';
        }

        if (res.success) {
          setLanguage(language);
          setLink(res.result);

          if (res.result_code !== '00') {
            showExpiredModal(res.result_code);
          } else {
            if (Cookies.get(match.params.linkId) && !isWorkflow) {
              if (res.result.flag_auth_email && !Cookies.get(`${match.params.linkId}_email`)) {
                setAuth(true);
              }
              setToken(Cookies.get(match.params.linkId))
              setLink(res.result);
              getList();
              return;
            }
            
            // if (res.result.flag_workflow) {
            //   setMessgage(t('There is no access permission to the URL.'));
            //   setExpired(true);
            //   getToken();
            // }

            if (res.result.flag_password) {
              setUsePwd(true)
            } else {
              getToken();
            }

            if (res.result.flag_auth_email && !isWorkflow) {
              setAuth(true);
            } else {
              return;
            }

            if (!res.result.flag_password &&
                !res.result.flag_auth_email) {
              getToken();
            }
          }
        } else {
          setLanguage(language);
          setMessgage(t(res.all));
          setLink(res.result);
          setExpired(true);
        }
      },
      (res) => {
        toastr.error(res.all);   
      }
    )
  }

  const closeAllModal = () => {
    // setAuth(false);
    setUsePwd(false);
  }

  const getToken = (password) => {
    const url ='/openapi/link/token';
    const params = {
      id: match.params.linkId,
      password: password,
      access_token: Cookies.get(`${match.params.linkId}_post`),
    }

    Api.postData(
      url,
      params,
      (res) => {
        console.log('getToken()', res, params);
        if (res.success) {
          setToken(res.result.token);
          Cookies.set(match.params.linkId, res.result.token, { expires: 1 });
          getList();

          // 우클릭 방지
          if (document && document.body) {
            document.body.addEventListener('contextmenu', e => e.preventDefault());
          }
        } else {
          toastr.error(res.all);
        }
      },
      (res) => {
        console.log('failure', res);
        toastr.error(res.all);
      }
    )
  }

  const showExpiredModal = (code) => {
    console.log('resultCode: ', code);
    if (code === '11') {
      setMessgage(t('Link is deleted.'));
    } else if (code === '12') {
      setMessgage(t('Link is expired.'));
    } else if (code === '13') {
      setMessgage(t('Exceeded Connection Count'));
    } else if (code === '94') {
      setMessgage(t('No access permission to link'));
    } else if (code === '95') {
      setMessgage(t('No access permission to link(workflow)'));
    }

    setExpired(true);
  }

  const getList = (sort = 'name', order = '0', node = '') => {
    const url = '/openapi/link/lists';
    const params = {
      token: Cookies.get(match.params.linkId),
      sort: sort,
      order: order,
      node: node,
    }

    Api.postData(
      url,
      params,
      (res) => {
        if (res.success) {
          console.log('getList()', res, params);
          setList(res.result);
          // getToken을 호출하지 않는 경우 우클릭 방지
          if (document && document.body) {
            document.body.addEventListener('contextmenu', e => e.preventDefault());
          }

          closeAllModal();
        } else {
          console.log('getList()', res, params);
          toastr.error(res.all);
        }
      },
      (res) => {
        console.log('failure', res);
        toastr.error(res.all);
      }
    )
  }

  const getPreviewUrl = (file) => {
    if (!Cookies.get(match.params.linkId)) {
      window.location.reload();
      return;
    }

    if (file.flag_download) {
      setUseDownload(true);
    }
  
    const mediaExt = ['mp4', 'mov', 'mpeg', 'avi', 'wmv', '3gp', 'mkv', 'mpg', 'ogg', 'mp3', 'ogv', 'webm', 'm4a', 'wav'];
    const supportExt = ['mp4', 'mov', 'mp3', 'm4a'];
    const audioExt = ['mp3', 'm4a'];
    const playVideo = mediaExt.includes(file.ext);
    const isSupport = supportExt.includes(file.ext);

    let url = '/openapi/link/preview';
    if (playVideo) {
      url = '/openapi/link/media_preview';
    }

    const params = {
      token: Cookies.get(match.params.linkId),
      fid: file.fid,
      lang: t('lang'),
      email: authEmail,
    }
    
    Api.postData(
      url,
      params,
      (res) => {
        if (res.success) {
          console.log('getPreviewUrl()', res, params);
          res.result.fid = file.fid;
          res.result.file_seq = file.file_seq;
          if (playVideo) {
            res.result.isMedia = true;

            if (audioExt.includes(file.ext)) {
              res.result.isAudio = true;
            }

            videoStore.setVideoOptions(res.result.url);
            res.result.url = res.result.url;
            res.result.name = file.name;
          }

          setPreviewData(res.result);
          setViewer(true);
        } else {
          console.log('getPreviewUrl()', res, params);
          toastr.error(res.all);
        }
      },
      (res) => {
        console.log('failure', res);
        toastr.error(res.all);
      }
    )
  }

  const getFileInfo = (seq) => {
    let value = null;
    list.files.some((file) => {
      if (String(file.file_seq) === seq) {
        value = file;
        return true;
      }
      return false;
    });
    return value;
  }

  const getDownloadUrl = (items) => {
    console.log(items);
    if (!Cookies.get(match.params.linkId)) {
      window.location.reload();
      return;
    }

    let url = '/openapi/link/download';

    const params = {
      token: Cookies.get(match.params.linkId),
      email: authEmail,
      lang: t('lang'),
    }

    let fids = items.filter(item => !item.includes('d'));
    let tempDirs = items.filter(item => item.includes('d'));
    let dirs = tempDirs.map(item => item.substring(1, item.length));

    if (fids.length === 1 && getFileInfo(fids[0]).is_encrypt) {
      shieldStore.shieldDownload(fids[0], Cookies.get(`${match.params.linkId}`), t('lang'), authEmail);
      return;
    }

    // ie 제외한 브라우저 파일 5개까지 각각 다운로드
    if (((!Api.detectIE() && !Api.detectSafari()) || Api.detectEdge()) &&
      fids.length <= 5 && dirs.length === 0) {
        console.log(fids.map(item => console.log(getFileInfo(item))));
        const shieldFids = fids.filter(item => getFileInfo(item).is_encrypt);
        const normalFids = fids.filter(item => !getFileInfo(item).is_encrypt);
        console.log('download', normalFids, shieldFids);
        eachDownload(url, normalFids, params, 0);

        setTimeout(() => {
          shieldStore.getShieldDownloadUrl(shieldFids, Cookies.get(`${match.params.linkId}`), t('lang'), authEmail, 0);
        }, (normalFids.length + 1.5) * 1000)
      return;
    }

    console.log(fids, dirs);
    if ((typeof fids !== 'string' && fids.length > 1) || dirs.length !== 0) {
      zipInit(fids, dirs);
      return;
    } else {
      params.file_seq = fids[0];
    }

    Api.postData(
      url,
      params,
      (res) => {
        if (res.success) {
          console.log('getDownloadUrl()', res, params);
          window.location.href = res.result.download_url;
        } else {
          console.log('getDownloadUrl()', res, params);
          toastr.error(res.all);
        }
      },
      (res) => {
        console.log('failure', res);
        toastr.error(res.all);
      }
    )
  }

  const eachDownload = (url, items, params, count) => {
    if (items.length - 1 < count) {
      return;
    }
    params.file_seq = items[count];
    params.lang = t('lang');

    Api.postData(
      url,
      params,
      (res) => {
        if (res.success) {
          console.log('eachDownload()', res, params);
          window.location.href = res.result.download_url;
          setTimeout(() => {
            eachDownload(url, items, params, count + 1);
          }, 1000);
        } else {
          console.log('eachDownload()', res, params);
          toastr.error(res.all);
        }
      },
      (res) => {
        console.log('failure', res);
        toastr.error(res.all);
      }
    )
  }

  const zipInit = (fids, dirs) => {
    const url = '/openapi/link/zip_init';
    const params = {
      token: Cookies.get(match.params.linkId),
    }

    Api.postData(
      url,
      params,
      (res) => {
        console.log('zipInit()', url, params, res);
        if (res.success) {
          zipDownload(res.task_key, fids, dirs);
        } else {
          console.log('failure', res);
          toastr.error(res.all);
        }
      },
      (res) => {
        console.log('[server]failure', res);
        toastr.error(res.all);
      }
    )
  }

  const zipDownload = (key, fids, dirs) => {
    const isDev = window.location.host.includes('dlink');
    const isStaging = window.location.host.includes('slink');
    let server = `//${isDev ? 'dapi' : isStaging ? 'sapi' : 'api'}.directcloud.jp`;
    if (window.location.host.includes('ismcloudone')) {
      server = `//${isDev ? 'dapiscs' : 'apiscs'}.ismcloudone.com`;
    }

    console.log(key);
    const url = `${server}/openapi/link/zip`;
    const form = document.createElement('form');
    form.setAttribute('action', url);
    form.setAttribute('method', 'post');
    const inputToken = document.createElement('input');
    inputToken.setAttribute('type', 'hidden');
    inputToken.setAttribute('name', 'token');
    inputToken.setAttribute('value', Cookies.get(match.params.linkId));
    form.appendChild(inputToken);
    const inputKey = document.createElement('input');
    inputKey.setAttribute('type', 'hidden');
    inputKey.setAttribute('name', 'task_key');
    inputKey.setAttribute('value', key);
    form.appendChild(inputKey);
    const inputFids = document.createElement('input');
    inputFids.setAttribute('type', 'hidden');
    inputFids.setAttribute('name', 'target_file');
    inputFids.setAttribute('value', fids.length !== 0 && fids.join(','));
    form.appendChild(inputFids);
    const inputDirs = document.createElement('input');
    inputDirs.setAttribute('type', 'hidden');
    inputDirs.setAttribute('name', 'target_dir');
    inputDirs.setAttribute('value', dirs.length !== 0 && dirs.join(','));
    form.appendChild(inputDirs);
    const inputEmail = document.createElement('input');
    inputEmail.setAttribute('type', 'hidden');
    inputEmail.setAttribute('name', 'email');
    inputEmail.setAttribute('value', authEmail);
    form.appendChild(inputEmail);
    const inputLang = document.createElement('input');
    inputLang.setAttribute('type', 'hidden');
    inputLang.setAttribute('name', 'lang');
    inputLang.setAttribute('value', t('lang'));
    form.appendChild(inputLang);
    document.body.appendChild(form);
    console.log(form);
    form.submit();

    getZipStatus(key)
  }

  const getZipStatus = (key) => {
    const url = '/openapi/link/zip_status';
    const params = {
      token: Cookies.get(match.params.linkId),
      task_key: key
    }

    Api.postZipStatus(
      url,
      params,
      (res) => {
        console.log('getZipStatus()', url, params, res);
        if (res.success) {
          if (res.status === 0 || res.status === 1) {
            if (res.status === 0) {
              const text = t('Compression is in progress for download.\r\nPlease wait.');
              const hText = t('When zipping a folder, files encrypted or subfolders unauthorized for download are excepted.');
              toast.onShow(text, hText);
            }

            setTimeout(() => {
              getZipStatus(key);
            }, 1000)
          } else if (res.status === 2) {
            setTimeout(() => {
              toast.onHide();
              toastr.success(t('Compressed file has been created.'));
            }, 2000)
          }
        } else {
          console.log('failure', res);
          setTimeout(() => {
            toast.onHide();
            toastr.error(res.all);
          }, 2000)
        }
      },
      (res) => {
        console.log('[server]failure', res);
        setTimeout(() => {
          toast.onHide();
          toastr.error(res.all);
        }, 2000)
      }
    )
  }

  const handleGetSizeInfo = () => {
    const url = '/openapi/link/getSizeInfo';
    const params = {
      token: Cookies.get(match.params.linkId),
    }

    Api.postData(
      url,
      params,
      (res) => {
        if (res.success) {
          console.log('success', res);
          const tempLink = link;
          tempLink.quota_size = res.result.quota_size;
          tempLink.upload_size = res.result.upload_size;
          tempLink.remaining_size = res.result.remaining_size;
          setLink(tempLink);
          console.log('handleGetSizeInfo', tempLink);
        } else {
          console.log('failure', res);
          toastr.error(res.all);
        }
      },
      (res) => {
        console.log('[server]failure', res);
        toastr.error(res.all);
      }
    )
  }

  const styles = {
    wrap: {
      backgroundColor: link && link.success === undefined && link.company ? '#282828' : '#fff',
      height: '100%',
    },
    container: {
      maxWidth: typeof window.orientation === 'undefined' && '1300px',
      margin: '0 auto',
      minHeight: showTopAlert ? 'calc(100% - 25px)' : '100%',
      height: showTopAlert ? 'calc(100% - 25px)' : '100%',
      position: showTopAlert && 'absolute',
      top: showTopAlert && '25px',
      width: showTopAlert && '100%',
    },
  }

  const loaderOption = {
    lines: 8,
    length: 4,
    width: 3,
    radius: 5,
    scale: 1.00,
    corners: 1,
    color: '#000',
    opacity: 0.25,
    rotate: 0,
    direction: 1,
    speed: 1,
    trail: 60,
    fps: 20,
    zIndex: 2e9,
    top: '50%',
    left: '50%',
    shadow: false,
    hwaccel: false,
    position: 'absolute'
};

  return (
    <div style={styles.wrap}>
      {showTopAlert && (
        <TopAlert
          onClick={(e) => {
            e.stopPropagation();
            sessionStorage.setItem('showTopAlert', 'false');
            setTopHeight(55);
            setShowTopAlert(false);
          }}
        />
      )}
      <div
        className="App"
        style={styles.container}
      >
        {
          token !== '' && !auth && !usePwd && !expired && (
            <Fragment>
              <Header
                logo={logo}
                link={link}
                setLanguage={(code) => {
                  setLanguage(code)
                }}
              />
              <Content
                link={link}
                list={list}
                getList={(sort, order, node) => {
                  getList(sort, order, node);
                }}
                setViewer={(isShow) => {
                  setViewer(isShow);
                }}
                setPreviewData={(name) => {
                  setPreviewData({
                    name: name,
                  })
                }}
                getPreviewUrl={(fid) => {
                  getPreviewUrl(fid);
                }}
                getDownloadUrl={(seq) => {
                  getDownloadUrl(seq);
                }}
                setUpload={(isShow) => {
                  setUploadModal(isShow);
                }}
                topHeight={topHeight}
              />
              {
                link &&
                link.flag_readable &&
                list &&
                list.files &&
                list.files.find(
                  item => item.shield_tag !== undefined &&
                    item.name.substring(item.name.lastIndexOf('.'), item.name.length) === '.irm'
                ) && (
                  <Shield
                    seq={list.files.filter(item => item.shield_tag !== undefined)[0].file_seq}
                    setShield={setShield}
                    setShieldData={setShieldData}
                  />
                )
              }
            </Fragment>
          )
        }
        {
          expired && message && (
            <ExpiredModal
              logo={logo}
              link={link}
              msg={message}
              setExpired={(isShow) => {
                setExpired(isShow);
              }}
              isDCB={!window.location.host.includes('ismcloudone')}
            />
          )
        }
        {
          usePwd && (
            <PasswordModal
              logo={logo}
              link={link}
              getToken={(password) => {
                getToken(password);
              }}
              setUsePwd={() => {
                setUsePwd(false);
              }}
              isDCB={!window.location.host.includes('ismcloudone')}
            />
          )
        }
        {
          auth && (
            <AuthModal
              setAuth={() => { setAuth(false) }}
              setAuthEmail={(email) => { setAuthEmail(email) }}
              linkId={match.params.linkId}
            />
          )
        }
        {
          upload && link && (
            <UploadModal
              link={link}
              currentNode={list && list.current_node}
              setUpload={(isShow) => {
                setUploadModal(isShow);
              }}
              email={authEmail}
              getList={() => {
                getList('name', 0, list.current_node);
              }}
              linkId={match.params.linkId}
              getLink={() => {
                getLink();
              }}
              handleGetSizeInfo={() => {
                handleGetSizeInfo();
              }}
            />
          )
        }
        {
          viewer && (
            <DocumentViewerModal
              previewData={previewData}
              setViewer={(isShow) => {
                setViewer(isShow);
              }}
              setPreviewData={() => {
                setPreviewData({});
              }}
              useDownload={useDownload}
              getDownloadUrl={(seq) => {
                getDownloadUrl(seq);
              }}
            />
          )
        }
        {
          shield && (
            <ShieldModal
              setShield={(isShow) => {
                setShield(isShow);
              }}
              data={shieldData}
            />
          )
        }
      </div>
      <Loader loaded={loaderStore.loaded} options={loaderOption} />
    </div>
  );
})

const App = () => {
  return (
    <Fragment>
      <Router>
        <Route exact path='/' component={Main} />
        <Route path='/:linkId' component={Main} />
      </Router>
    </Fragment>
  )
}

toastr.options = {
  "closeButton": false,
  "debug": false,
  "newestOnTop": false,
  "progressBar": false,
  "positionClass": "toast-bottom-center",
  "preventDuplicates": false,
  "onclick": null,
  "showDuration": "300",
  "hideDuration": "1000",
  "timeOut": "7000",
  "extendedTimeOut": "1000",
  "showEasing": "swing",
  "hideEasing": "linear",
  "showMethod": "fadeIn",
  "hideMethod": "fadeOut"
}

export default (App);
