import React from 'react';
// import ReactDOM from 'react-dom';
import TweenMax from 'gsap';

export default class LoaderBox extends React.Component {
  box1;
  box2;
  box3;
  box4;
  box5;
  timer;
  componentDidMount() {
    this.animate();
    this.timer = setInterval(this.animate.bind(this), 2000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    this.timer = null;
  }

  animate() {
    const $1 = this.box1;
    const $2 = this.box2;
    const $3 = this.box3;
    const $4 = this.box4;
    const $5 = this.box5;
    const tl = TweenMax;

    tl.set([$1, $2, $3, $4, $5], {
      yPercent: 0,
      scaleY: 1,
    });

    tl.to($1, 0.5, {
      yPercent: -100,
      scaleY: 3,
      delay: 0,
    });
    tl.to($1, 0.5, {
      yPercent: 0,
      scaleY: 1,
      delay: 0.4,
    });

    tl.to($2, 0.5, {
      yPercent: -100,
      scaleY: 3,
      delay: 0.3,
    });
    tl.to($2, 0.5, {
      yPercent: 0,
      scaleY: 1,
      delay: 0.7,
    });

    tl.to($3, 0.5, {
      yPercent: -100,
      scaleY: 3,
      delay: 0.6,
    });
    tl.to($3, 0.5, {
      yPercent: 0,
      scaleY: 1,
      delay: 1.0,
    });

    tl.to($4, 0.5, {
      yPercent: -100,
      scaleY: 3,
      delay: 0.9,
    });
    tl.to($4, 0.5, {
      yPercent: 0,
      scaleY: 1,
      delay: 1.3,
    });

    tl.to($5, 0.5, {
      yPercent: -100,
      scaleY: 3,
      delay: 1.2,
    });
    tl.to($5, 0.5, {
      yPercent: 0,
      scaleY: 1,
      delay: 1.6,
    });

    // use TimelineMax
    // tl = new TimelineMax();

    // tl.to($1, 0.5, {
    //   yPercent: -100,
    //   scaleY: 3,
    // }, 0.0)
    // .to($1, 0.5, {
    //   yPercent: 0,
    //   scaleY: 1
    // }, 0.4);

    // tl.to($2, 0.5, {
    //   yPercent: -100,
    //   scaleY: 3,
    // }, 0.3)
    // .to($2, 0.5, {
    //   yPercent: 0,
    //   scaleY: 1
    // }, 0.7);

    // tl.to($3, 0.5, {
    //   yPercent: -100,
    //   scaleY: 3,
    // }, 0.6)
    // .to($3, 0.5, {
    //   yPercent: 0,
    //   scaleY: 1
    // }, 1.0);

    // tl.to($4, 0.5, {
    //   yPercent: -100,
    //   scaleY: 3,
    // }, 0.9)
    // .to($4, 0.5, {
    //   yPercent: 0,
    //   scaleY: 1
    // }, 1.3);

    // tl.to($5, 0.5, {
    //   yPercent: -100,
    //   scaleY: 3,
    // }, 1.2)
    // .to($5, 0.5, {
    //   yPercent: 0,
    //   scaleY: 1
    // }, 1.6);
  }

  render() {
    const { hidden, width, height } = this.props;
    return (
      <div
        hidden={hidden}
        style={{
          height: '100%',
        }}
      >
        <div
          style={{
            display: 'table',
            tableLayout: 'fixed',
            width: '100%',
            height: '100%',
          }}
        >
          <div
            style={{
              display: 'table-cell',
              width: '100%',
              verticalAlign: 'middle',
              textAlign: 'center',
            }}
          >
            <svg
              style={{
                display: 'block',
                width,
                height,
                margin: '0px auto',
              }}
              viewBox="0 0 32 32"
              width="32"
              height="32"
            >
              <g className="loading3-box-group">
                <path
                  ref={(ref) => {
                    this.box1 = ref;
                  }}
                  d="M0 12 V20 H4 V12z"
                  transform="translate(2)"
                  fill="#ccc"
                />
                <path
                  ref={(ref) => {
                    this.box2 = ref;
                  }}
                  d="M0 12 V20 H4 V12z"
                  transform="translate(8)"
                  fill="#bbb"
                />
                <path
                  ref={(ref) => {
                    this.box3 = ref;
                  }}
                  d="M0 12 V20 H4 V12z"
                  transform="translate(14)"
                  fill="#aaa"
                />
                <path
                  ref={(ref) => {
                    this.box4 = ref;
                  }}
                  d="M0 12 V20 H4 V12z"
                  transform="translate(20)"
                  fill="#999"
                />
                <path
                  ref={(ref) => {
                    this.box5 = ref;
                  }}
                  d="M0 12 V20 H4 V12z"
                  transform="translate(26)"
                  fill="#888"
                />
              </g>
            </svg>
          </div>
        </div>
      </div>
    );
  }
}
