import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEn from './translation.en';
import translationKo from './translation.ko';
import translationJa from './translation.ja';

const resources = {
  en: {
    translation: translationEn,
  },
  ko: {
    translation: translationKo,
  },
  ja: {
    translation: translationJa,
  }
};

i18n.use(initReactI18next)
  .init({
    resources,
    lng: "en",
    fallbackLng: 'en',
    keySeparator: false,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;