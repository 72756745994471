import { observable, action, decorate } from 'mobx';

class VideoStore {
  constructor() {
    this.videoOptions = null;
  }

  setVideoOptions = (url) => {
    this.videoOptions = {
      controls: true,
      autoplay: true,
      sources: {
        src: url,
        type: 'video/mp4',
      },
    };
  };
}

decorate(VideoStore, {
  videoOptions: observable,
  setVideoOptions: action,
})

const videoStore = new VideoStore();

export default videoStore;
export { VideoStore };
