// @flow
import * as React from 'react';

import { useTranslation } from "react-i18next";

const TopAlert = (props) => {
  const { t } = useTranslation();
  const {
    onClick,
  } = props;

  const height = 25;

  return (
    <div
      className="p-relative w-100 px-3 pull-left"
      style={{
        height,
        backgroundColor: '#ffe793',
        lineHeight: `${height}px`,
        fontSize: '13px',
        position: 'fixed',
        zIndex: '99999'
      }}
    >
      <p
        className="m-0 font-size-12"
      >
        {t('Internet Explorer will be no longer supported soon. Please use other browsers such as Chrome or Edge.')}
        <i
          className="fa fa-close ml-2"
          style={{
            color: '#d6a50d',
            cursor: 'pointer',
          }}
          onClick={onClick}
        />
      </p>
    </div>
  );
};

export default TopAlert;
