import { observable, decorate, action } from 'mobx';

class UploadStore {
  constructor() {
    this.files = [];
    this.progress = 0;
    this.fileName = '';
    this.current = 0;
    this.count = 0;
    this.completed = false;
    this.currentStatus = '';

    this.client_key = Math.random().toString(36).substr(2, 16);
  }

  setFiles = (files) => {
    this.files = files;
  }
  
  setProgress = (per) => {
    this.progress = per;
    this.files[this.current].percent = this.progress;
  }

  // sync
  setWebsocketData = (socketData) => {
    console.log(socketData);
    if (socketData.data.type !== 'upload_status') {
      return;
    }

    this.files.map((item, i) => {
      if (item.fileKey === socketData.data.data.file_key) {
        if (socketData.data.data.status === 'error') {
          this.files[i].error_message = socketData.data.data.error_message;
        }

        this.files[i].status = socketData.data.data.status;
        this.currentStatus = `${socketData.data.data.status}-${socketData.data.data.file_key}`;
      }
    })

    
    const completed = this.files.every(item =>
      (item.status === 'complete' ||
      item.status === 'error' ||
      item.status === 'rejected_document_upload' ||
      item.status === 'security_document_registered' ||
      item.status === 'security_document_encrypted' ||
      item.status === 'virus_detected')
    )

    if (completed) {
      uploadStore.setComplete();
    }
  }

  setCurrentStatus = (status) => {
    this.currentStatus = status;
  }

  // async
  setStatus = (status, msg) => {
    this.files[this.current].status = status;
    if (msg) {
      this.files[this.current].error_message = msg;
    }
  }
  
  setFileName = (name) => {
    this.fileName = name;
  }

  setCurrent = (current) => {
    this.current = current;
  }

  setCount = (count) => {
    this.count = count;
  }

  setComplete = () => {
    this.completed = true;
  }

  setInit = () => {
    this.progress = 0;
    this.current = 0;
    this.count = 0;
    this.completed = false;
  }
}

decorate(UploadStore, {
  files: observable,
  progress: observable,
  fileName: observable,
  current: observable,
  currentStatus: observable,
  count: observable,
  completed: observable,
  setCurrentStatus: action,
  setFiles: action,
  setProgress: action,
  setStatus: action,
  setFileName: action,
  setCurrent: action,
  setCount: action,
  setWebsocketData: action,
  setInit: action,
});

const uploadStore = new UploadStore();

export default uploadStore;
export { UploadStore };
