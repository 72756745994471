import React from 'react';

import { useTranslation } from "react-i18next";

const Shield = (props) => {
  const { t } = useTranslation();
  const styles ={
    wrap: {
      position: 'absolute',
      width: '100%',
      maxWidth: '1300px',
      bottom: 0,
      border: '1px solid #c9c9c9',
      background: '#fff',
      padding: '16px',
      fontSize: '13px',
      color: '#333333',
      textAlign: 'center',
    },
    p: {
      margin: 0,
    },
    span: {
      padding: '0px 20px',
      color: '#0054FF',
      textDecoration: 'underline',
      cursor: 'pointer',
    }
  }

  return (
    <div className="Shield" style={styles.wrap}>
      <p style={styles.p}>
        {t('This list contains security document(.irm extension).')}
        <span
          className="shield_download"
          style={{
            ...styles.span,
            display: 'inline-block'
          }}
          onClick={() => {
            // handleGetShieldInfo();
            props.setShield(true);
          }}
        >
          {t('How to Use Security Document')}
        </span>
      </p>
      <p className="shield_mobile" style={{ ...styles.p, display: 'none' }}>
        {t('Security Document with IRM extension can not be decrypted from mobile, so please use on PC.')}
      </p>
    </div>
  );
}

export default Shield;
