import React, { useState } from 'react';
import fileSize from 'filesize';

import { useTranslation } from "react-i18next";

const PasswordModal = (props) => {
  const { t } = useTranslation();
  const [ password, setPassword ] = useState('');

  const checkPassword = () => {
    props.getToken(password);
  }

  const renderItem = (title, content) => {
    return (
      <div className='pm__item-wrap'>
        <div className='pm__item-title'>{title}</div>
        {
          content === 'password' ? (
            <input
              type='password'
              className='pm__item-input'
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  checkPassword();
                }
              }}
              autoFocus
            />
          ) : (
            <div className='pm__item-content'>{content}</div>
          )
        }
      </div>
    );
  }

  return (
    <div className='PasswordModal'>
      <div className='pm__dialog'>
        <div className='pm__dialog-header'>
          <div style={{ float: 'left' }} className='pm__dialog-title'>
            <a
              href={props.isDCB ? "https://directcloud.jp" : "https://ismcloudone.com"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={props.link.logo || props.logo}
                alt=""
                style={{
                  width: '130px',
                  height: '30px',
                }}
              />
            </a>
          </div>
          <div style={{ float: 'right' }} className='pm__dialog-title'>
            <img
              src="/static/bundles/img/ic_popup_ink.png"
              height="24"
              alt=""
            />
          </div>
        </div>
        <div className='pm__dialog-content'>
          {!props.link.flag_hide_sender && renderItem(t('Company Name'), props.link.company)}
          {!props.link.flag_hide_sender && renderItem(t('Sender'), props.link.user)}
          {renderItem(t('Expiration Date'), props.link.expiry_date || t('No time limit'))}
          {
            props.link.flag_readable && (
              renderItem(
                t('Permission'),
                props.link.flag_docsview === 0 ? t('Download Only') :
                props.link.flag_docsview === 1 ? t('Preview Only') :
                props.link.flag_docsview === 2 ? t('Preview/Download') : null
              )
            )
          }
          {renderItem(t('The count of visits'), `${props.link.connection_count} / ${props.link.connection_limit_count || t('Unlimited')}`)}
          {
            props.link.flag_writable && renderItem(
              t('Capacity Restriction'),
              props.link.quota_size === 0 ? t('Capacity Unlimited') :
                `${fileSize(props.link.quota_size)} (${t('Remaining ***').replace('***', fileSize(props.link.remaining_size))})`
            )
          }
          {renderItem(t('Password'), 'password')}
          <div className='pm__dialog-button-wrap'>
            <button
              className='pm__dialog-button'
              onClick={() => {
                // password
                checkPassword();
              }}
            >
              {t('OK')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PasswordModal;
