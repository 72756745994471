import React, { Component } from 'react';
import Loader from 'react-loader';

export default class ToastContent extends Component {
  state = {
    loaded: false,
    hover: false,
    isError: false,
  };

  setLoaded = (loaded = true) => this.setState({ loaded });

  setError = (loaded = true, isError = true) =>
    this.setState({ loaded, isError });

  handleMouseEnter = () => this.setState({ hover: true });

  handleMouseLeave = () => this.setState({ hover: false });

  render() {
    const { loaded, hover, isError } = this.state;
    const { text, hText } = this.props;
    const size = 38;
    const styles = {
      root: {
        position: 'fixed',
        zIndex: 10000,
        bottom: 0,
        left: 0,
      },
      wrap: {
        borderRadius: 3,
        backgroundColor: isError ? '#bd362f' : '#51a351',
        boxShadow: '0 0 15px 0 hsla(0, 0%, 0%, 0.4)',
        display:'inline-block',
        position: 'relative',
      },
      loaderWrap: {
        width: size,
        height: size,
        display: 'inline-block',
        position: 'relative',
      },
      p: {
        color: '#fff',
        whiteSpace: 'pre-wrap',
        fontSize: '14px',
      },
      info: {
        width: size,
        height: size,
        display: 'inline-block',
        position: 'relative',
      },
      ic: {
        color: '#bceabc',
        fontSize: '1.9rem',
        lineHeight: `${size}px`,
      },
      hover: {
        position: 'absolute',
        zIndex: 1,
        bottom: '70%',
        left: '92%',
        width: 220,
        border: 'solid 1px #d5d4dc',
        borderRadius: 3,
        borderBottomLeftRadius: 0,
        backgroundColor: '#f9f7f7',
      },
      pHover: {
        color: '#444650',
        fontSize: '11px',
      },
    };

    return (
      <div className="w-100 text-center" style={styles.root}>
        <div className="py-3" style={styles.wrap}>
          <div className="ml-3" style={styles.loaderWrap}>
            {loaded ? (
              <i className="fa fa-check" style={{ ...styles.ic, color: '#fff' }} />
            ) : (
              <Loader loaded={loaded} color="#fff" lines={10} width={2.2} radius={5} length={4.5} />
            )}
          </div>

          <div className="px-3" style={{ display: 'inline-block' }}>
            <p className="m-0" style={loaded ? { ...styles.p, lineHeight: `${size}px` } : styles.p}>
              {text}
            </p>
          </div>

          <div className="mr-3" style={styles.info}>
            {
              !loaded && (
                <i
                  className="fa fa-info-circle"
                  style={styles.ic}
                  onMouseEnter={this.handleMouseEnter}
                  onMouseLeave={this.handleMouseLeave}
                />
              )}
          </div>

          {hover && (
            <div className="p-3" style={styles.hover}>
              <p className="mb-0" style={styles.pHover}>
                {hText}
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
}
