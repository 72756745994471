import React from 'react';
import { useTranslation } from "react-i18next";

import ShieldDownload from '../bottom/ShieldDownload';

const ShieldModal = (props) => {
  const { t } = useTranslation();
  const { data, setShield } = props;
  return (
    <div className='ShieldModal'>
      <div className='sm__dialog'>
        <div className='sm__header'>
          <span style={{ margin: 0 }}>
            {t('Notice of DirectCloud-SHIELD Security Document')}
          </span>
          <div
            className='sm__header-btn'
            onClick={() => {
              props.setShield(false);
            }}
          >
            <img
              src="/static/bundles/img/ic_close.svg"
              alt=""
              style={{
                width: '10px',
                height: '10px',
              }}
            />
          </div>
        </div>
        <div className='sm__dialog-content'>
          <ShieldDownload data={data} setShield={setShield} />
        </div>
      </div>
    </div>
  )
}

export default ShieldModal;
