import React, { useState } from 'react';
import toastr from 'toastr';
import Cookies from 'js-cookie';

import { useTranslation } from "react-i18next";

import Api from '../../api/Api';

const AuthModal = (props) => {
  const { t } = useTranslation();

  const [ email, setEmail ] = useState('');

  const checkReceiver = () => {
    const url = '/openapi/link/checkReceiver';
    const params = {
      token: Cookies.get(props.linkId),
      email: email,
    }

    Api.postData(
      url,
      params,
      (res) => {
        console.log('checkReceiver::', res, params);
        if (res.success) {
          props.setAuth();
          props.setAuthEmail(params.email);
          Cookies.set(`${props.linkId}_email`, params.email, { expires: 1 });
        } else {
          toastr.error(res.all);
        }
      },
      (res) => {
        toastr.error(res.all);   
      }
    )
  }

  return (
    <div className='AuthModal'>
      <div className='am__dialog'>
        <div className='am__dialog-header'>
          <div className='am__dialog-title'>
            <h6 style={{ margin: 0, padding: '12px 0' }}>{t('Recipient Authentication')}</h6>
          </div>
        </div>
        <div className='am__dialog-content'>
          <p>{t('This link is requested to enter the email address.')}</p>
          <p>{t('Please enter the email address you received the link accordingly.')}</p>
          <input
            type='text'
            placeholder={t('Enter Email Address')}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                checkReceiver();
              }
            }}
            autoFocus
          />
          <div className='am__dialog-btn-wrap'>
            <button
              className='am__dialog-btn'
              onClick={() => {
                checkReceiver();
              }}
            >
              {t('OK')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AuthModal;
