import React from 'react'

const ExpiredModal = (props) => {
  return (
    <div className='ExpiredModal'>
      <div className='em__dialog'>
        <div className='em__dialog-header'>
          <div style={{ float: 'left' }} className='em__dialog-title'>
            <a
              href={props.isDCB ? "https://directcloud.jp" : "https://ismcloudone.com"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={(props.link && props.link.logo) || props.logo}
                alt=""
                style={{ width: '130px' }}
              />
            </a>
          </div>
          <div style={{ float: 'right' }} className='em__dialog-title'>
            <img
              src="/static/bundles/img/ic_popup_ink.png"
              height="24"
              alt=""
            />
          </div>
        </div>
        <div className='em__dialog-content'>
          <div>{props.msg}</div>
        </div>
      </div>
    </div>
  )
}

export default ExpiredModal;
