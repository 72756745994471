import React, { useState, useCallback, Fragment } from "react";
import toastr from "toastr";
import fileSize from "filesize";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import { observer } from "mobx-react";

import "./Content.css";

import ContentList from "./ContentList";

import uploadStore from "../../mobx/UploadStore";
import Api from "../../api/Api";

const Content = observer((props) => {
  const { t } = useTranslation();
  const { link, list, getList } = props;

  const [checkedAll, setCheckedAll] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);

  const { files, setFiles } = uploadStore;

  // sort order
  // name, datetime, size
  // 0, 1
  const [sort, setSort] = useState("name");
  const [order, setOrder] = useState(false);

  const reducer = (acc, cur) => acc + cur;

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (!link.flag_writable) {
        return;
      }

      if (files.length === 0) {
        props.setUpload(true);
      }

      const temp = files;
      const tempSize = [];
      const tempNames = temp.map((item) => item.name);

      temp.map((item) => tempSize.push(item.size));

      acceptedFiles.forEach((item) => {
        if (tempNames.includes(item.name)) {
          return;
        }

        tempSize.push(item.size);
        if (
          props.quotaSize !== 0 &&
          props.remainingSize < tempSize.reduce(reducer)
        ) {
          toastr.remove();
          toastr.error(t("You exceed the maximum capacity."));
          return;
        }

        temp.push(item);
      });

      setFiles([...temp]);
    },
    [files]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleCheckAllItems = (checked) => {
    setCheckedAll(checked);

    const checkedList = Array.prototype.slice.call(
      document.querySelectorAll('input[name="list_check"]'),
      0
    );
    let temp = [];
    checkedList.forEach((item, i) => {
      checkedList[i].checked = checked;
    });

    if (checked) {
      checkedList.forEach((item) => {
        temp.push(item.value);
        setCheckedItems(temp);
      });
    } else {
      setCheckedItems([]);
    }
  };

  const handleCheckItem = (e) => {
    const checkedList = Array.prototype.slice.call(
      document.querySelectorAll('input[name="list_check"]'),
      0
    );
    let tempChecked = [];
    checkedList.forEach((item) => {
      tempChecked.push(item.checked);
    });

    const isChecked = (checked) => {
      return checked;
    };

    if (tempChecked.every(isChecked)) {
      setCheckedAll(true);
    } else {
      setCheckedAll(false);
    }

    let temp = checkedItems;
    if (e.target.checked) {
      temp.push(e.target.value);
      console.log(e.target.value);
      setCheckedItems(temp);
    } else {
      let temp = checkedItems;
      temp.forEach((item, i) => {
        if (item === e.target.value) {
          temp.splice(i, 1);
          setCheckedItems(temp);
        }
      });
    }
  };

  const onlyUpload =
    link.type === "folder" && !link.flag_readable && link.flag_writable;
  let permission = "";
  if (link.flag_docsview === 0) {
    permission = t("Download Only");
  } else if (link.flag_docsview === 1) {
    permission = t("Preview Only");
  } else if (link.flag_docsview === 2) {
    permission = t("Preview/Download");
  }

  return (
    <div
      {...getRootProps({
        onClick: (event) => event.stopPropagation(),
      })}
      style={{ height: "calc(100% - 55px)" }}
    >
      <input {...getInputProps()} id="upload-input" />
      <div
        className="Content"
        style={{
          paddingBottom: list.ad && list.ad.banner !== '' && list.ad.link !== '' && '100px',
        }}
      >
        <div className="content__info-wrap">
          <div className="content__info">
            {!link.flag_hide_sender && (
              <span
                className="content__info-sender"
                title={`${link.company} ${link.user}`}
              >
                <i className="fa fa-user" />
                {t("Sender")} : {link.company} {link.user}
              </span>
            )}
            {
              link.flag_readable && (
                <span>
                  <img
                    src="/static/bundles/img/ic_sharelink.png"
                    alt=""
                    style={{
                      position: "relative",
                      top: "-1px",
                      margin: "3px",
                      width: 11,
                      height: 11,
                    }}
                  />
                  {t("Permission")} : {permission}
                </span>
              )
            }
            <span>
              <i className="fa fa-bolt" style={{ margin: '3px 5px' }} />
              {t("Expiration Date")} : {link.expiry_date || t("No time limit")}
            </span>
            <span>
              <i className="fa fa-clock-o" />
              {t("The count of visits")} : {link.connection_count} /{" "}
              {link.connection_limit_count || t("Unlimited")}
            </span>
          </div>
          <div className="content__info-btns">
              {list && (list.files || list.folders) && (
                <i
                  className="fa fa-refresh"
                  onClick={() => {
                    getList("name", 0, list.current_node);
                    handleCheckAllItems(false);
                    setSort("name");
                    setOrder(false);
                  }}
                />
              )}
              {
                <i
                  className="fa fa-arrow-left"
                  onClick={() => {
                    if (list.parent_node) {
                      getList(sort, order ? "1" : "0", list.parent_node);
                    }
                  }}
                  disabled={!list.parent_node}
                  style={{
                    backgroundColor: !list.parent_node && "#eaeaea",
                    cursor: !list.parent_node && "not-allowed",
                  }}
                />
              }
              {!onlyUpload && link.flag_docsview !== 1 && (
                <div
                  className="content__down-wrap"
                  style={{ display: "inline-block" }}
                >
                  <button
                    onClick={() => {
                      if (checkedItems.length >= 1) {
                        handleCheckAllItems(false);
                        props.getDownloadUrl(checkedItems);
                      } else {
                        toastr.remove();
                        toastr.error(t("There is no file selected."));
                      }
                    }}
                  >
                    <span className="content__down-text">{t("Download")}</span>
                    <i className="fa fa-download content__down-i"></i>
                  </button>
                </div>
              )}
              {link.flag_writable && (
                <button
                  onClick={props.setUpload}
                  disabled={!list.flag_upload}
                  style={{
                    backgroundColor: !list.flag_upload && "#428bca90",
                    borderColor: !list.flag_upload && "#357ebd90",
                    opacity:
                      !list.flag_upload &&
                      (Api.detectIE() || Api.detectEdge()) &&
                      "0.6",
                  }}
                >
                  <span className="content__up-text">{t("Upload")}</span>
                  <i className="fa fa-upload content__up-i"></i>
                </button>
              )}
              {
                // link.flag_writable && (
                //   <div className='content__info-sizewrap'>
                //     <i className="fa fa-hdd-o" style={{ fontSize: '16px' }}></i>
                //       <span className='content__info-cr'>{t('Capacity Restriction')}</span>
                //       <span
                //         style={{
                //           color: '#8C8C8C',
                //           fontWeight: '600',
                //         }}
                //       >
                //         {
                //           link.quota_size === 0 ?
                //             t('Capacity Unlimited') :
                //             // `${fileSize(link.quota_size)} (${t('Remaining ***').replace('***', fileSize(link.remaining_size))})`
                //             `${fileSize(link.upload_size)} (${(100 - (link.remaining_size / link.quota_size * 100)).toFixed(1)}%) / ${fileSize(link.quota_size)}`
                //         }
                //       </span>
                //   </div>
                // )
              }
            </div>
            {
              list.ad &&
              list.ad.banner !==''&&
              list.ad.link !=='' && (
                <div
                  style={{
                    position: 'fixed',
                    bottom: 0,
                    textAlign: 'center',
                    maxWidth: '1260px',
                    width: '100%',
                  }}
                >
                  <img
                    src={list.ad.banner}
                    alt=""
                    style={{
                      width: '100%',
                      maxWidth: '1000px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      window.open(list.ad.link, '_blank');
                    }}
                  />
                </div>
              )
            }
          </div>
          <Fragment>
            {!onlyUpload && (
              <ContentList
                list={list}
                link={link}
                getPreviewUrl={(file) => {
                  props.getPreviewUrl(file);
                }}
                getDownloadUrl={(seq) => {
                  props.getDownloadUrl(seq);
                }}
                getList={(sort, order, node) => {
                  getList(sort, order, node);
                  setCheckedItems([]);
                  handleCheckAllItems(false);
                }}
                useDownload={link.flag_docsview !== 1}
                handleCheckAllItems={(checked) => {
                  handleCheckAllItems(checked);
                }}
                handleCheckItem={(e) => {
                  handleCheckItem(e);
                }}
                checkedAll={checkedAll}
                setCheckedAll={(checked) => {
                  setCheckedAll(checked);
                }}
                flagDocsView={link.flag_docsview}
                sort={sort}
                order={order}
                setSort={(sort) => setSort(sort)}
                setOrder={(order) => setOrder(order)}
              />
            )}
          </Fragment>
          {
            onlyUpload && (
              <div className="content__upload">
                <div className="content__upload-wrap">
                  <div className="content__upload-title">
                    <span>{t("This link is only available for uploading.")}</span>
                    <img
                      src="/static/bundles/img/ic_popup_ink.png"
                      height="24"
                      alt=""
                    />
                  </div>
                  <div className="content__upload-content">
                    <p>
                      {t(
                        "The file completed uploading will be sent to the link creator and can not be recalled. So, please pay attention to this before uploading."
                      )}
                    </p>
                  </div>
                </div>
              </div>
            )
          }
      </div>
    </div>
  );
});

export default Content;
