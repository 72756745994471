import { decorate, action, observable } from 'mobx';
import Api from '../api/Api';

class ShieldStore {
  constructor() {
    this.linkId = '';
  }
  
  shieldDownload = (id, token, lang, email) => {
    console.log('shieldDownload', id, token);

    const isDev = window.location.host.includes('dlink');
    const isStaging = window.location.host.includes('slink');
    let server = `//${isDev ? 'dapi' : isStaging ? 'sapi' : 'api'}.directcloud.jp`;
    if (!window.location.host.includes('directcloud')) {
      server = `//${isDev ? 'dapiscs' : 'apiscs'}.ismcloudone.com`;
    }

    const url = `${server}/openapi/link/shield/download`;

    const form = document.createElement('form');
    form.setAttribute('action', url);
    form.setAttribute('method', 'post');

    const inputToken = document.createElement('input');
    inputToken.setAttribute('type', 'hidden');
    inputToken.setAttribute('name', 'token');
    inputToken.setAttribute('value', token);
    form.appendChild(inputToken);

    const inputLang = document.createElement('input');
    inputLang.setAttribute('type', 'hidden');
    inputLang.setAttribute('name', 'lang');
    inputLang.setAttribute('value', lang);
    form.appendChild(inputLang);

    const inputSeq = document.createElement('input');
    inputSeq.setAttribute('type', 'hidden');
    inputSeq.setAttribute('name', 'file_seq');
    inputSeq.setAttribute('value', id);
    form.appendChild(inputSeq);

    const inputEmail = document.createElement('input');
    inputEmail.setAttribute('type', 'hidden');
    inputEmail.setAttribute('name', 'email');
    inputEmail.setAttribute('value', email);
    form.appendChild(inputLang);

    document.body.appendChild(form);
    form.submit();
  }
  
  getShieldDownloadUrl = (seqs, token, lang, email, count) => {
    if (seqs.length - 1 < count) {
      return;
    }

    const isDev = window.location.host.includes('dlink');
    const isStaging = window.location.host.includes('slink');
    let server = `//${isDev ? 'dapi' : isStaging ? 'sapi' : 'api'}.directcloud.jp`;
    if (!window.location.host.includes('directcloud')) {
      server = `//${isDev ? 'dapiscs' : 'apiscs'}.ismcloudone.com`;
    }

    const url = '/openapi/link/shield/download/1';
    const params = {
      token,
      lang,
      file_seq: seqs[count],
      email, 
    }

    Api.postData(url, params, (res) => {
      console.log(res, seqs, count);
      if (res.success) {
        window.location.href = res.download_url;

        setTimeout(() => {
          this.getShieldDownloadUrl(seqs, token, lang, email, count + 1);
        }, 1000);
      }
    })
  }

  setLinkId = (id) => {
    this.linkId = id;
    console.log(id);
  }
}

decorate(ShieldStore, {
  linkId: observable,
  shieldDownload: action,
  getShieldDownloadUrl: action,
  setLinkId: action,
})

const shieldStore = new ShieldStore();

export default shieldStore;
export { ShieldStore };
