import React, { Fragment, useState } from 'react';
import { observer } from 'mobx-react';

import { useTranslation } from "react-i18next";

import videoStore from '../../mobx/VideoStore';
import VideoPlayer from './VideoPlayer';

const DocumentViewerModal = observer((props) => {
  const { t } = useTranslation();
  const [isVideoError, setIsVideoError] = useState(false);
  const [isNotSupportFormat, setIsNotSupportFormat] = useState(false);

  const { name, preview_url, file_seq, isMedia, isAudio, url } = props.previewData;

  const handleSetVideoError = () => {
    setIsVideoError(true);
  }

  const handleSetIsNotSupportFormat = () => {
    setIsNotSupportFormat(true);
  }

  console.log('videoOptions', videoStore.videoOptions, isMedia, props);
  return (
    <div className='DocumentViewerModal'>
      <div className='dvm__header'>
        <div className='dvm__header-wrap'>
          <span className='dvm__header-title'>
            {name}
          </span>
        </div>
        {
          props.useDownload && (
            <Fragment>
              <img
                className='dvm__header-download-btn'
                src='/static/bundles/img/btn_download.svg'
                alt=''
                onClick={() => {
                  props.getDownloadUrl([String(file_seq)]);
                }}
              />
              <span
                style={{
                  width: '0px',
                  height: '16px',
                  marginTop: '14px',
                  borderRight: 'solid 1px #307BCC',
                  borderLeft: 'solid 1px #3E99FC',
                  position: 'absolute',
                  right: '45px',
                }}
              />
            </Fragment>
          )
        }
        <div
          className='dvm__header-btn'
          onClick={() => {
            props.setViewer(false)
          }}
        >
          <img
            src="/static/bundles/img/ic_close.svg"
            alt=""
            style={{
              width: '10px',
              height: '10px',
            }}
          />
        </div>
      </div>
      {
        (url || preview_url) ? (
          isMedia ? (
            isVideoError ? (
              <div className='dvm__not-supported'>
                <h2>{t('The connection has been terminated since the data transmission has been interrupted for a certain period of time.Please try it again.')}</h2>
              </div>
            ) : (
              <VideoPlayer
                { ...videoStore.videoOptions }
                handleSetVideoError={() => {
                  handleSetVideoError();
                }}
                handleSetIsNotSupportFormat={() => {
                  handleSetIsNotSupportFormat();
                }}
                isVideo={!isAudio}
              />
            )
          ) : (
            <iframe
              id="kukudocsIframe"
              className="dvm__iframe"
              name="iframe"
              title="Document"
              src={preview_url}
            />
          )
        ) : (
          <div className='dvm__not-supported'>
            <h2>{t('Preview cannot be displayed for selected file type. Please download the file.')}</h2>
          </div>
        )
      }
    </div>
  );
})

export default DocumentViewerModal;
