import React, { Fragment } from 'react';
import filesize from 'filesize';

import { useTranslation } from 'react-i18next';

import getFileIcon from '../../helpers/FileIcon';

const ContentList = (props) => {
  const { t } = useTranslation();
  const {
    list,
    checkedAll,
    useDownload,
    getList,
    handleCheckItem,
    handleCheckAllItems,
    getPreviewUrl,
    getDownloadUrl,
    flagDocsView,
    sort,
    order,
    setSort,
    setOrder,
    link,
  } = props;


  const renderTitle = () => {
    return (
      <div className='cl__li-t'>
        {
          // 다운로드 권한이 없으면 버튼, 체크박스 나타나지 않음
          useDownload && (
            <input
              type='checkbox'
              className='cl__li-input'
              style={{
                margin: '10px',
                cursor: 'pointer',
              }}
              checked={checkedAll}
              onChange={(e) => {
                handleCheckAllItems(e.target.checked);
              }}
            />  
          )
        }
        <div className='cl__li-name-t'>
          <span
            style={{ paddingLeft: '5px'}}
            onClick={() => {
              // 정렬
              setOrder(!order);
              setSort('name');
              getList('name', !order ? '1' : '0', list.current_node);
            }}
          >
            {t('File Name')}
            {order && sort === 'name' && ' ▼'}
            {!order && sort === 'name' && ' ▲'}
          </span>
        </div>
        <div className='cl__li-info-t'>
          <span
            onClick={() => {
              // 정렬
              setOrder(!order);
              setSort('size');
              getList('size', !order ? '1' : '0', list.current_node);
            }}
          >
            {t('Size')}
            {order && sort === 'size' && ' ▼'}
            {!order && sort === 'size' && ' ▲'}
          </span>
        </div>
        <div className='cl__li-info-t'>
          <span
            onClick={() => {
              // 정렬
              setOrder(!order);
              setSort('datetime');
              getList('datetime', !order ? '1' : '0', list.current_node);
            }}
          >
            {t('Time')}
            {order && sort === 'datetime' && ' ▼'}
            {!order && sort === 'datetime' && ' ▲'}
          </span>
        </div>
      </div>
    );
  }

  const renderFileListItem = (item) => {
    return (
      <div
        className='cl__li'
        key={item.file_seq}
      >
        {
          // 다운로드 권한이 없으면 버튼, 체크박스 나타나지 않음
          useDownload && (
            <input
              className='cl__li-input'
              type='checkbox'
              style={{
                margin: '10px',
                cursor: 'pointer',
                float: 'left',
              }}
              name='list_check'
              value={item.file_seq}
              onChange={(e) => {
                handleCheckItem(e);
              }}
            />
          )
        }
        <img className="cl__li-img" src={getFileIcon(item.ext)} alt='' />
        <div
          className='cl__li-name'
          style={{ fontWeight: 'bold' }}
          onClick={() => {
            // 0 다운로드 / 1 미리보기 / 2 다운로드/미리보기
            if (flagDocsView === 0) {
              getDownloadUrl([String(item.file_seq)]);
            } else if (flagDocsView === 1 || flagDocsView === 2) {
              getPreviewUrl(item);
            }
          }}
        >
          <div style={{ display: 'grid' }}>
            <h4>
              {item.name}
            </h4>
            <h4 className='cl-li-mobile'>{item.datetime} | {item.size && filesize(item.size)}</h4>
          </div>
        </div>
        <div className='cl__li-info'>
          <span>{item.size && filesize(item.size)}</span>
        </div>
        <div className='cl__li-info'>
          <span>{item.datetime}</span>
        </div>
        {
          link.flag_docsview !== 1 && (
            <div
            className='cl__li-download'
            style={{
              display: 'none',
              visibility: 'hidden',
            }}
          >
            <button
              onClick={() => {
                props.getDownloadUrl([String(item.file_seq)]);
              }}
            >
              <i className="fa fa-download" style={{ margin: 0 }}></i>
            </button>
          </div>
          )
        }
      </div>
    );
  }

  const renderFolderListItem = (item) => {
    return (
      <div
        className='cl__li'
        key={item.dir_seq}
      >
        {
          useDownload && (
            <input
              className='cl__li-input'
              type='checkbox'
              style={{
                margin: '10px',
                cursor: 'pointer',
                float: 'left',
              }}
              name='list_check'
              value={`d${item.dir_seq}`}
              onChange={(e) => {
                handleCheckItem(e);
              }}
            />
          )
        }
        <img className="cl__li-img" src={getFileIcon('folder')} alt='' />
        <div
          className='cl__li-name'
          style={{ fontWeight: 'bold' }}
          onClick={() => {
            getList(sort, order ? '1' : '0', item.node);
          }}
        >
          <div style={{ display: 'grid' }}>
            <h4 className='cl__li-name-f'>
              {item.name}
            </h4>
          </div>
        </div>
        <div className='cl__li-info'>
          <span></span>
        </div>
        <div className='cl__li-info'>
          <span>{item.datetime}</span>
        </div>
        {
          link.flag_docsview !== 1 && (
            <div
              className='cl__li-download'
              style={{
                display: 'none',
                visibility: 'hidden',
                marginTop: '-8px',
              }}
            >
              <button
                onClick={() => {
                  props.getDownloadUrl([`d${item.dir_seq}`]);
                }}
              >
                <i className="fa fa-download" style={{ margin: 0 }}></i>
              </button>
            </div>
          )
        }
      </div>
    );
  }

  const renderList = () => {
    return (
      <Fragment>
        {
          list.folders && list.folders.length !== 0 &&
            list.folders.map(item => renderFolderListItem(item))
        }
        {
          list.files && list.files.length !== 0 &&
            list.files.map(item => renderFileListItem(item))
        }
      </Fragment>
    );
  }

  return (
    <div className='ContentList'>
      {renderTitle()}
      {list && renderList()}
    </div>
  )
}

export default ContentList;
